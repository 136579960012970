export const DATE_FORMAT = "MMMM do yyyy hh:mm a";
export const DAY_FORMAT = "MMMM do yyyy";
export const TIME_FORMAT = "hh:mm a";

export const PORTAL_BASE_URL = import.meta.env.VITE_PORTAL_BASE_URL;

export const OpenReelSettingsURL = `${
	import.meta.env.VITE_CAPTURE_BASE_URL
}/settings`;

export const OpenReelLoginURL = `${
	import.meta.env.VITE_CAPTURE_BASE_URL
}/login`;
