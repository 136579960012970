import { Toast as BootstrapToast } from "react-bootstrap";
import { useToast } from "@app/context/Toast";

const Toast = () => {
	const { show, hideToast, text, title, variant } = useToast();

	return (
		<div className="toast-container">
			<BootstrapToast
				bg={variant || "primary"}
				onClose={hideToast}
				show={show}
				delay={3000}
				autohide
			>
				{title && (
					<BootstrapToast.Header>
						<img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
						<strong className="me-auto">{title}</strong>
					</BootstrapToast.Header>
				)}
				<BootstrapToast.Body>{text}</BootstrapToast.Body>
			</BootstrapToast>
		</div>
	);
};

export default Toast;
