import { createContext, useContext, useReducer } from "react";

export interface ToastState {
	show: boolean;
	text: string;
	variant: string;
	title: string;
}

interface DispatchToast {
	type: string;
	text?: string;
}

interface ToastContextProps {
	toast?: ToastState;
	dispatch?: React.Dispatch<DispatchToast>;
}

const initialState: ToastState = {
	show: false,
	text: "",
	variant: "",
	title: "",
};

export const ToastContext = createContext<ToastContextProps>({});

function toastReducer(state: ToastState, action: DispatchToast) {
	switch (action.type) {
		case "SHOW": {
			return {
				...state,
				show: true,
				text: action.text,
			};
		}
		case "SHOW_SUCCESS": {
			return {
				...state,
				show: true,
				text: action.text,
				variant: "success",
			};
		}
		case "SHOW_WARNING": {
			return {
				...state,
				show: true,
				text: action.text,
				variant: "warning",
			};
		}
		case "SHOW_ERROR": {
			return {
				...state,
				show: true,
				text: action.text,
				variant: "danger",
			};
		}
		case "HIDE": {
			return {
				...initialState,
			};
		}
		default: {
			return state;
		}
	}
}

export function ToastProvider({ children }) {
	const [toast, dispatch] = useReducer(toastReducer, { ...initialState });
	const value = { toast, dispatch };

	return <ToastContext.Provider value={value}>{children}</ToastContext.Provider>;
}

export function useToast() {
	const context = useContext(ToastContext);
	if (!context)
		throw new Error("useToast must be used within the ToastProvider");

	const { toast, dispatch } = context;

	const showToast = (text: string) => {
		dispatch({ type: "SHOW", text });
	};

	const showSuccessToast = (text: string) => {
		dispatch({ type: "HIDE" });
		dispatch({ type: "SHOW_SUCCESS", text });
	};

	const showWarningToast = (text: string) => {
		dispatch({ type: "HIDE" });
		dispatch({ type: "SHOW_WARNING", text });
	};

	const showErrorToast = (text: string) => {
		dispatch({ type: "HIDE" });
		dispatch({ type: "SHOW_ERROR", text });
	};

	const hideToast = () => {
		dispatch({ type: "HIDE" });
	};

	return {
		...toast,
		showToast,
		showSuccessToast,
		showWarningToast,
		showErrorToast,
		hideToast,
	};
}
