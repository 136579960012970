import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "@app/context/Auth";
import { HeadProvider } from "react-head";
import { ToastProvider } from "@app/context/Toast";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retryOnMount: false,
			retry: false,
		},
	},
});

const AppProviders = ({ children }) => {
	return (
		<HeadProvider>
			<AuthProvider>
				<QueryClientProvider client={queryClient}>
					<ToastProvider>
						{children}
						{import.meta.env.VITE_ENV === "development" && <ReactQueryDevtools />}
					</ToastProvider>
				</QueryClientProvider>
			</AuthProvider>
		</HeadProvider>
	);
};

export default AppProviders;
