import { useAuth } from "../context/Auth";
import { OpenReelSettingsURL } from "../lib/constants/shared";
import Button from "./Button";

const WebinarsEnabledProtected: React.FC<
	React.PropsWithChildren<Record<string, unknown>>
> = (props) => {
	const { isWebinarsEnabled, hasInitialLoadingDone } = useAuth();
	if (!hasInitialLoadingDone) {
		return <></>;
	}
	if (!isWebinarsEnabled) {
		return (
			<div className="container pt-5 d-flex justify-content-center">
				<div className="d-flex flex-column align-items-center mt-5 bg-light p-4 rounded">
					<h1>Webinars is not enabled for your account</h1>
					<p>Please contact the team admin, or enable it in settings</p>
					<Button as="a" size="lg" href={OpenReelSettingsURL}>
						Go to settings
					</Button>
				</div>
			</div>
		);
	}
	return <>{props.children}</>;
};

export default WebinarsEnabledProtected;
