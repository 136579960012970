import clsx from "clsx";
import { forwardRef } from "react";

type VariantBase =
	| "primary"
	| "secondary"
	| "success"
	| "danger"
	| "warning"
	| "info"
	| "light"
	| "dark";

export type ButtonVariant =
	| VariantBase
	| `outline-${VariantBase}`
	| "link"
	| "icon";

interface SharedProps {
	variant?: ButtonVariant;
	size?: "sm" | "lg";
	rounded?: "circle" | "pill";
	showSpinner?: boolean;
}

interface AnchorProps
	extends React.DetailedHTMLProps<
		React.AnchorHTMLAttributes<HTMLAnchorElement>,
		HTMLAnchorElement
	> {
	as: "a";
}

interface ButtonElProps
	extends React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	> {
	as?: "button" | undefined;
}

export type ButtonProps = (AnchorProps | ButtonElProps) & SharedProps;
type Ref = React.ForwardedRef<HTMLButtonElement> &
	React.ForwardedRef<HTMLAnchorElement>;

const Button = forwardRef(
	(
		{
			children,
			variant = "primary",
			size = "sm",
			rounded,
			showSpinner,
			as = "button",
			className,
			...rest
		}: React.PropsWithChildren<ButtonProps>,
		ref: Ref
	) => {
		const Element = as;
		return (
			<Element
				//@ts-expect-error
				ref={ref}
				className={clsx(
					`btn btn-${variant}`,
					`rounded${rounded ? "-" + rounded : ""}`,
					`btn-${size}`,
					className
				)}
				{...rest}
			>
				{showSpinner ? (
					<span
						className="spinner-border spinner-border-sm"
						role="status"
						aria-hidden="true"
					></span>
				) : (
					children
				)}
			</Element>
		);
	}
);

Button.displayName = "Button";

export default Button;
